 <!--
 * =====================================================================
 * 작 업 명  : 세션 테스트
 * 파 일 명  : Session.vue
 * 변경이력
 * 2023-08-03 / 정태화 / 최초작성
 * =====================================================================
 -->
<template>
	<div class="container">
    <button @click="getSession()">세션 조회</button>
	</div>
</template>
<script>
  import { $axios } from "dot-framework";
	export default
	{
		data()
		{
			return {
			}
		},
		methods:
		{
			// 세션 조회
			getSession()
			{
        let url = "/user-app/v1/login/session";
        $axios.get(url).then((response) => {
          if (response.status == 200) {
            console.log("* response.data => ");
            console.log(response.data);
            alert(response.data.SESSION_ID);
          }
        });
			}
		}
	}
</script>
<style>
</style>
